<!-- 设置考试场次 -->
<template>
    <div class="sessions">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="80px">
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入场地/机构/名称/监考老师"></el-input>
            </el-form-item>
            <el-form-item label="">
                <div class="" style="margin-left: 20px;">
                    <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                        查询
                    </el-button>
                    <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="empty">
                        重置
                    </el-button>
                </div>
            </el-form-item>
            
        </el-form>
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                    @click="newlyAdded">
                    新增
                </el-button>
                
            </div>
            
        </div>
        <div class="" style="margin-left:22px ;margin-top: 20px;">
            <button class="button-1" type="button" @click="toTab(0)" :class="tab===0?'active':''">考试大类</button>
            <button class="button-2" type="button" @click="toTab(1)" :class="tab===1?'active':''">单科考试</button>
            <button class="button-3" type="button" @click="toTab(2)" :class="tab===2?'active':''">综合考场</button>
        </div>
        <!-- 考试大类 表格-->
        <el-table :data="testData" border stripe style="width:97.9%;flex: 1;" height="650"  
        v-if="tab===0" :key="0" @sort-change="abc">
            <el-table-column prop="number" sortable="custom" label="编码" align="center" width="150">
            </el-table-column>
            <el-table-column prop="status" label="状态" sortable="custom" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">准备</span>
                    <span v-else-if="scope.row.status==2">完成</span>
                </template>
            </el-table-column>
            <el-table-column prop="exam_name" sortable="custom" label="名称" align="center" width="240">
            </el-table-column>
            <el-table-column prop="category_date" sortable="custom" label="设置日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="start_date" sortable="custom" label="考试开始日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="end_date" sortable="custom" label="考试截至日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="enroll_date" sortable="custom" label="报名开始日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="enroll_date2"  sortable="custom" label="报名截至日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="certificate_status" sortable="custom" label="准考证发布状态" align="center" width="200">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">未发布</span>
                    <span v-else-if="scope.row.status==2">发布</span>
                </template>
            </el-table-column>
            <el-table-column  label="操作" fixed="right"  align="center" width="200">
                <template slot-scope="scope">
                    <el-button class="edit" icon="el-icon-edit" size="mini" 
                        @click="editTestData(scope.$index,scope.row)">
                        编辑
                    </el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" 
                        @click="deltTestData(scope.$index,scope.row)">
                        删除
                    </el-button>
                </template>
                
            </el-table-column>
            
            <!-- <el-table-column prop="remark" label="说明" align="center" width="350">
            </el-table-column> -->
        </el-table>
        <!-- 单科考试 表格-->
        <el-table :data="singleData" border stripe style="width:97.9%;flex: 1;" height="650" 
         v-if="tab===1" :key="1" @sort-change="abc">
            <el-table-column prop="number" sortable="custom" label="编码" align="center" width="150">
            </el-table-column>
            <el-table-column prop="status" label="状态" sortable="custom" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">准备</span>
                    <span v-else-if="scope.row.status==2">完成</span>
                </template>
            </el-table-column>
            <el-table-column prop="course" label="学科" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="level_id" label="等级" sortable="custom"  align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_name" label="名称" sortable="custom" align="center" width="300">
            </el-table-column>
            <el-table-column prop="exam_date" label="考试日期" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="start_date" label="开始时间" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="end_date" label="截止时间" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="price" label="收费标准/科" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="exam_num" label="计划考生数" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_duration" label="考试时长(hr)" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="invigilator" label="监考老师" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="remark" label="说明" sortable="custom" align="center" width="350">
            </el-table-column>
            <el-table-column  label="操作" align="center" width="200" fixed="right">
                <template slot-scope="scope">
                    <el-button class="edit" icon="el-icon-edit" size="mini" 
                    @click="editSingleData(scope.$index,scope.row)">
                        编辑
                    </el-button>
                    <!-- <el-button class="checkBtn" icon="el-icon-view" size="mini"
                     @click="check(scope.$index, scope.row)">
                        查看更多
                    </el-button> -->
                    <el-button type="danger" icon="el-icon-delete" size="mini" 
                    @click="deltSingleData(scope.$index,scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 综合考场 表格-->
        <el-table :data="roomData" border stripe style="width:97.9%;flex: 1;" height="650" 
         v-if="tab===2" :key="2" @sort-change="abc">
            <el-table-column prop="number" label="编码" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_status" label="状态" sortable="custom" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.exam_status==1">准备</span>
                    <span v-else-if="scope.row.exam_status==2">完成</span>
                </template>
            </el-table-column>
            <el-table-column prop="exam_room" label="场地" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="org_id" label="所属机构/学科" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_name" label="名称" sortable="custom" align="center" width="300">
            </el-table-column>
            <el-table-column prop="exam_date" label="考试日期" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="start_date" label="开始时间" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="end_date" label="截止时间" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_num" label="可安排考生数量" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="exam_duration" label="考试时长(hr)" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="invigilator" label="监考老师"  sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="remark" label="说明" align="center" sortable="custom" width="350">
            </el-table-column>
            <el-table-column  label="操作" align="center" width="200" fixed="right">
                <template slot-scope="scope">
                    <el-button class="edit" icon="el-icon-edit" size="mini"
                    @click="editRoomData(scope.$index,scope.row)">
                        编辑
                    </el-button>
                    <!-- <el-button class="checkBtn" icon="el-icon-view" size="mini"
                     @click="checks(scope.$index, scope.row)">
                        查看更多
                    </el-button> -->
                    <el-button type="danger" icon="el-icon-delete" size="mini"
                     @click="deltRoomData(scope.$index,scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
        @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    // testTime:"",//考试日期
                    // fullName:"",//人员姓名
                    // subject:"",//选择学科
                    // subjectChoose:[],//选择学科选项
                    // select:"",//选择
                    // choose:[],//选择选项
                    search:""//搜索
                },
                tab:0,//下标
                currentPage: 1,
                currentLimit: 20,
                total: 0,
                testData:[],//考试大类数据
                singleData:[],//单科考试数据
                roomData:[],//综合考场数据
                arr:[]
            }
        },
        created() {
            this.getList()
        },
        activated() {
            // console.log("缓存")
            this.getList()
        },
        methods:{
            
            getList(){
              if(this.tab==0){//考试大类
                  this.$request({url: '/api/ExamCategory/list', method: 'POST', data:{
                      page: this.currentPage,
                      limit: this.currentLimit,
                      exam_name:this.form.search,
                      sort:this.arr
                  }}).then(res=>{
                      // console.log(res)
                      this.testData=res.data.list
                      this.total =res.data.count
                  })
              }else if(this.tab==1){
                  this.$request({url: '/api/Exam/list', method: 'POST', data:{
                      page: this.currentPage,
                      limit: this.currentLimit,
                      exam_name:this.form.search,
                      sort:this.arr
                  }}).then(res=>{
                      // console.log(res)
                      this.singleData=res.data.list
                      this.total =res.data.count
                  })
              }else if(this.tab==2){
                  this.$request({url: '/api/Examcomprehensive/list', method: 'POST', data:{
                      page: this.currentPage,
                      limit: this.currentLimit,
                      exam_name:this.form.search,
                      sort:this.arr
                  }}).then(res=>{
                      // console.log(res)
                      this.roomData=res.data.list
                      this.total =res.data.count
                  })
              }    
            },
            empty(){//重置
                this.form.search=""//关键字
            },
            query(){//查询
                this.currentPage=1
                this.getList()
            },
            handleSizeChange(val) {
                this.currentLimit = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                if(this.tab==0){//考试大类新增
                    this.$router.push({//
                        path: "addExamBigClass"
                    })
                }else if(this.tab==1){//单科考试新增
                    this.$router.push({
                        path: "addSingle"
                    })
                }else if(this.tab==2){//综合考场新增
                    this.$router.push({
                        path: "addSynthetical"
                    })
                }
            },
            toTab(index) {//赋值下标
                this.tab = index;
                this.getList()
            },
            check(index,row){//查看更多 单科考试-学员资料
            // console.log(index,row);
                this.$router.push({
                    path: "singleData",
                    query: {
                        id:row.id
                    }
                })
            },
            checks(index,row){//查看更多 综合考场-学员资料
                this.$router.push({
                    path: "syntheticalData",
                    query: {
                        id:row.id 
                    }
                })
            },
            editTestData(index,row){//跳转考试大类编辑
                this.$router.push({
                    path: "editExamBigClass",
                    query: {
                        id:row.id 
                    }
                })
            },
            editSingleData(index,row){//跳转单科考试编辑
                this.$router.push({
                    path: "editSingle",
                    query: {
                        id:row.id 
                    }
                })
            },
            editRoomData(index,row){//跳转综合考场编辑
                this.$router.push({
                    path: "editSynthetical",
                    query: {
                        id:row.id 
                    }
                })
            },
            deltTestData(index,row){//考试大类删除
                this.$confirm('是否删除此条数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$request({
                        url: '/api/ExamCategory/del',
                        method: 'POST',
                        data: {
                            id: row.id
                        }
                    }).then(res=>{
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.testData.splice(index, 1)
                        }
                    })
                })
            },
            deltSingleData(index,row){//单科考试删除
                // console.log(row)
                this.$confirm('是否删除此条数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$request({
                        url:'/api/Exam/del',
                        method:'POST',
                        data:{
                            id:row.id
                        }
                    }).then(res=>{
                        if(res.code==1){
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.singleData.splice(index, 1)
                        }
                    })
                })
            },
            deltRoomData(index,row){//综合考场删除
                this.$confirm('是否删除此条数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$request({
                        url:'/api/Examcomprehensive/del',
                        method:'POST',
                        data:{
                            id:row.id
                        }
                    }).then(res=>{
                        if(res.code==1){
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.roomData.splice(index, 1)
                        }
                    })
                })
            },
            //排序
            abc(i){ 
                console.log(i)
                this.testData=[]//考试大类数据
                this.singleData=[]//单科考试数据
                this.roomData=[]//综合考场数据
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .sessions{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .form {
        padding-top: 34px;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    /* .form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    } */
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    
    ::v-deep .el-icon-download {
        font-weight: bold !important;
    }
    /* 列表 统计表按钮 */
    .button-1 {
        font-size: 16px;
        display: inline-block;
        width: 100px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #ccc;
        border-radius: 3px;
        cursor: pointer;
    }
    
    .button-2 {
        font-size: 16px;
        display: inline-block;
        width: 100px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #ccc;
        margin-left: 10px;
        border-radius: 3px;
        cursor: pointer;
    }
    .button-3 {
        font-size: 16px;
        display: inline-block;
        width: 100px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #ccc;
        margin-left: 10px;
        border-radius: 3px;
        cursor: pointer;
    }
    /* 选中后的样式 */
    .active {
        background-color: #CDEEEB;
        border: 1px solid #29726C;
    }
    /* 查看更多 按钮 */
    .checkBtn{
        color:#FFFFFF;
        background: #18BC9C;
        font-size: 12px;
        border-radius: 2px;
        border: none;
    }
    .edit{
        background: #18BC9C;
        color: #fff;
    }
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 20px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
